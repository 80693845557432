import { getAbTestFeature } from "@solvari/common-fe/ab-testing";

function initYouShouldAskChat() {
  if (getAbTestFeature<boolean>("you-should-ask-chat") ?? false) {
    const script = document.createElement("script");
    script.src = "https://app.youshouldask.nl/chat/js/eS7GHe/28OSPF";
    document.querySelector("head")!.insertAdjacentElement("beforeend", script);
  }
}

export { initYouShouldAskChat };
